
@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700');
@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@400;700');
@import url('reset.css');

// Variables
$black: #121212;
$charcoal: #191919;
$grey: #353535;
$teal: #008080;
$white: #EBFFFF;
$font-primary: 'Ubuntu', sans-serif;
$font-secondary: 'Cabin', sans-serif;
$radius: 3px;
$space: 20px;
$whitespace: 2.5rem;

// Global
html {
  font-size: 100%;
  scroll-behavior: smooth;
}
body {
  font-family: $font-secondary;
  font-weight: 400;
  line-height: 1.75;
}

// Typography
p {margin-bottom: 1rem;}
h1, h2, h3, h4, h5 {
  font-family: $font-primary;
  font-weight: 400;
  line-height: 1.3;
  margin: 0 0 $whitespace;
}
h1 {
  font-size: 3.052rem;
  @media (max-width: 768px) {
    font-size: 2.441rem;
  }
}
h2 {font-size: 2.441rem;}
h3 {font-size: 1.953rem;}
h4 {font-size: 1.563rem;}
h5 {font-size: 1.25rem;}
small, .text_small {font-size: 0.8rem;}
// Layout
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
main {
  flex-grow: 1;
  margin-top: 69px;
  header {
    text-align: center;
  }
}
section {
  padding: $whitespace 0;
}
article {
  margin: 0 auto;
  max-width: 700px;
  padding: $space;
  a {
    color: $teal;
    &:hover {
      border-bottom: 1px solid $teal;
    }
  }
}
footer {
  background-color: $charcoal;
  border-top: 1px solid $grey;
  margin-top: $whitespace;
  padding: $whitespace 0;
  text-align: center;
  p {
    margin-top: 30px;
  }
  a {
    margin: 0 10px;
  }
}
.site-header {
  a {
    color: $white;
    margin: 0 10px;
    text-decoration: none;
  }
  .MuiPaper-root {
    background-color: $charcoal;
    background-image: none;
  }
}
.title {
  h1 {
    margin: 0 auto $space;
    max-width: 800px;
  }
  p {
    font-size: 1.15rem;
    margin: 0 auto $space;
    max-width: 600px;
  }
}

// Routes
#about {
  .skillset {
    background-color: $charcoal;
  }
  img {
    border-radius: $radius;
    width: 200px;
  }
  .stats {
    border-bottom: 1px solid $grey;
    margin-bottom: $space;
    padding-bottom: $space;
    text-align: center;
  }
  h5 {
    margin-left: -40px;
    text-align: center;
  }
  .MuiChip-root {
    margin-bottom: 10px;
    margin-right: 10px;
    max-width: 170px;
    width: 100%;
    @media (max-width: 768px) {
      max-width: 47%;
    }
  }
}
#contact, #services {
  a {
    color: $teal;
    &:hover {
      border-bottom: 1px solid $teal;
    }
  }
}
#home {
  .intro {
    background: 
      linear-gradient(rgba(18, 18, 18, 0.5),rgba(18, 18, 18, 0.5)), 
      url('background.jpeg');
    background-size: cover;
    padding: 120px 20px;
    @media (max-width: 768px) {
      padding: 60px 20px;
    }
    .cta {
      div {
        justify-content: center;
      }
      .MuiButton-contained {
        margin-right: 10px;
      }
    }
  }
  .companies {
    background-color: $charcoal;
    border-bottom: 1px solid $grey;
    border-top: 1px solid $grey;
    text-align: center;
    img {
      filter: brightness(0) invert(1);
      max-height: 30px;
      max-width: 150px;
      &.mobius-risk-group {
        margin-top: -30px;
        max-height: 60px;
      }
    }
  }
  #projects {
    padding-top: $whitespace + $whitespace;
  }
}
#not-found{
  text-align: center;
}
#services {
  .service {
    background-color: $charcoal;
    border-radius: $radius;
    margin: 0 auto $whitespace;
    padding: $whitespace;
    @media (max-width: 768px) {
      padding: 20px;
    }
    .service-image {
      height: 100%;
    }
    img {
      border-radius: $radius;
      height: 100%;
      object-fit: cover;
      &.even {
        float: right;
      }
    }
    .MuiChip-root {
      margin-bottom: 10px;
      margin-right: 10px;
      width: 47%;
      &:nth-child(even) {
        margin-right: 0px;
      }
    }
  }
}

// Components
.project {
  .MuiPaper-root {
    background-color: $charcoal;
    background-image: none;
  }
}

.social {
  background-color: $charcoal;
  border-radius: $radius;
  float: left;
  margin-top: $whitespace;
  padding: $space;
  a {
    color: $white;
    margin-right: $space;
  }
  span {
    margin-right: $space;
  }
}